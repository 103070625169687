import React from 'react'
import { withRouter } from "react-router"
import { Modal, Button, Icon, Container,
         Header, Card, Grid, Label } from 'semantic-ui-react'
import { _read } from '../../Lib/canvas'
import Cookies from 'js-cookie'
import {_runPipeline, _addCollectionToStudy} from '../../Lib/canvas'

class Stain extends React.Component {
    constructor (props) {
        super(props)
        this.state = { 
            token: JSON.parse(
                Cookies.get("deepstain-access")).token,
            pipelineChecked: true, 
            temp_slides: [], 
            temp_study: undefined,
            temp_pipe: undefined, 
            createPredOpen: false,
            studies: [],
            pipes: [], 
            models: [], 
            slides: [], 
            regions: true,
            pages: {"slides": 1, "pipelines": 1, "predictors": 1}
        }
    }

    componentDidMount(){
        this.getPipes();
        this.getModels(); 
        this.getSlides();
        this.getStudies(); 
    }

    getStudies = async () => {
        const {token} = this.state
        const s = await _read(token, "studies")
        const res = s

        if (res.length === 0)
          return;
        const sOptions = []
        for (let i = 0; i < res.length; i++){
          const mn = res[i].name
          const mid = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mid
          }
          sOptions.push(t)
        }
        this.setState({studies: sOptions})
    }

    getPipes = async () => {
        const {token, pages, pipes} = this.state
        const nu_pipes = await _read(token, "pipelines", 100, pages["pipelines"])
        const res = nu_pipes.results
        if (nu_pipes.totalPages >= pages["pipelines"]){
          pages["pipelines"]++;
        }
        else {
          return;
        }
        if (res.length === 0)
          return;
        const pipesOptions = pipes
        for (let i = 0; i < res.length; i++){
          const mn = res[i].name
          const mid = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mid
          }
          pipesOptions.push(t)
        }
        this.setState({pipes: pipesOptions, pages: pages})
    }

    getModels = async () => {
        const {token, models, pages} = this.state
        const nu_models = await _read(token, "predictors", 200, pages["predictors"])
        if (nu_models.totalPages >= pages["predictors"]){
          pages["predictors"]++;
        }
        else {
          return;
        }
        const res = nu_models.results
        if (res.length === 0)
          return;
        const modelOptions = models
        for (let i = 0; i < res.length; i++){
          const mn = res[i].name
          const mid = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mid
          }
          modelOptions.push(t)
        }
        this.setState({models: modelOptions, pages: pages})
    }

    getSlides = async () => {
        const {token, slides, pages} = this.state
        const nu_slides = await _read(token, "slides", 100, pages["slides"])
        if (nu_slides.totalPages >= pages["slides"]){
          pages["slides"]++;
        }
        else {
          return;
        }
        const res = await nu_slides.results
        if (res.length === 0)
          return;
        const slideOptions = slides
        for (let i = 0; i < res.length; i++){
          const element = res[i]
          if(element.status === "S" || element.status === "U"){
            const mn = element.name
            const mid = element.id
            const t = {
              key: mn,
              text: mn,
              value: mid,
              status: element.status
            }
            slideOptions.push(t)
          }
          console.log(slideOptions)
        }
        this.setState({slides: slideOptions, pages: pages})
    }

    createPipelineRun = async () => {
        const {token, temp_slides, temp_pipe, temp_study} = this.state;
        this.setState({isCreatePredictionLoading: true})
        for (const slide of temp_slides){
            const r = await _runPipeline(token, {
                slide_id: slide, 
                pipeline_id: temp_pipe, 
                regions: true
            })
            const coll_body = await r.json()
            await _addCollectionToStudy(token, {
                collectionId: coll_body['collection'], 
                studyId: temp_study
            })
        }
        
        this.setState({isCreatePredictionLoading: false})
        this.setState({createPredOpen: false})
    }
    

    render () {
      const {createPredOpen, pipelineChecked, studies,
             pipes, slides, temp_study, temp_slides, temp_pipe} = this.state;
      Array.prototype.removeByIndex = function(i) {
        if(!Number.isInteger(i) || i < 0) {
            // i must be an integer
            return this;
        }
        return this.filter((f, indx) => indx !== i)
      }
      return <div style={{padding: '2vh'}}>          
        <Modal
            size="fullscreen"
            onClose={() => this.setState({createPredOpen: false})}
            onOpen={() => this.setState({createPredOpen: true})}
            open={createPredOpen}
            trigger={
                <Button fluid color="blue" size="massive" icon labelPosition='left'>
                    Stain
                    <Icon name='pencil' />
                </Button>}>
            <Modal.Header>Stain</Modal.Header>
            <Container fluid>
            <Grid columns={3}>
              <Grid.Column>
                    <div style={{padding: '1vh'}}>
                      <Header>Pipelines</Header>
                      <Card.Group style={{maxHeight: '50vh', overflowY: 'auto'}}>
                          {studies && pipes.map(
                              (pipe) => <Card fluid
                                      style={{backgroundColor: temp_pipe === pipe.value ? "#6982A5" : ""}} 
                                      onClick={() => {this.setState({temp_pipe: pipe.value})}}>
                                      <Card.Content>
                                          <Card.Header>{pipe.text}</Card.Header>
                                      </Card.Content>
                                  </Card>)}
                      <Button fluid circular color="blue" onClick={this.getPipelines}>Load more</Button>
                      </Card.Group>
                    </div>
              </Grid.Column>
              <Grid.Column>
                  <div style={{padding: '1vh'}}>
                    <Header>Studies</Header>
                    <Card.Group style={{maxHeight: '50vh', overflowY: 'auto'}}>
                        {studies && studies.map(
                            (study) => <Card fluid
                                    style={{backgroundColor: temp_study === study.value ? "#6982A5" : ""}} 
                                    onClick={() => {this.setState({temp_study: study.value})}}>
                                    <Card.Content>
                                        <Card.Header>{study.text}</Card.Header>
                                    </Card.Content>
                                </Card>)
                        }
                        <Button fluid circular color="blue" onClick={this.getStudies}>Load more</Button>
                    </Card.Group>
                    </div>
              </Grid.Column>
              <Grid.Column>
                <div style={{padding: '1vh'}}>
                    <Header>Slides</Header>
                    <Card.Group style={{maxHeight: '50vh', overflowY: 'auto'}}>
                    {slides && slides.map(
                            (slide) => <Card fluid
                                    style={{backgroundColor: temp_slides.includes(slide.value) ? "#6982A5" : ""}} 
                                    onClick={
                                      () => {
                                        let {temp_slides} = this.state; 
                                        if (!temp_slides.includes(slide.value)){
                                            temp_slides.push(slide.value); 
                                        } else {
                                            temp_slides = temp_slides.removeByIndex(
                                              temp_slides.indexOf(slide.value))
                                        }
                                        this.setState({temp_slides: temp_slides})
                                      }}>
                                    <Card.Content>
                                        <Card.Header>{slide.text}</Card.Header>
                                        <Label color={slide.status === 'S' ? "green" : "orange" }>
                                          {slide.status === 'S' ? "Stamped" : "Uploaded" }
                                        </Label>
                                    </Card.Content>
                                </Card>)}
                    <Button fluid circular color="blue" onClick={this.getSlides}>Load more</Button>
                    </Card.Group>
                    </div>
              </Grid.Column>
            </Grid>
            </Container>
            <Modal.Actions>
                <Button                 
                    content="Cancel"
                    labelPosition='right'
                    icon='times' color='red' 
                    onClick={() => this.setState({
                    createPredOpen: false})}>
                </Button>
                <Button
                    content="Create"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={pipelineChecked ? this.createPipelineRun : this.createPrediction}
                    positive
                />
            </Modal.Actions>
        </Modal>
      </div>
    }
  }

export default withRouter(Stain)