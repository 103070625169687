import React, { Component }  from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import 'semantic-ui-css/semantic.min.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Cookies from 'js-cookie';


import SignIn from "./Components/Auth/Signin.jsx"
import Home from "./Components/Welcome.jsx"
import Dashboard from './Components/Dashboard.jsx'
import Studies from "./Components/Pages/Studies.jsx";
import Slides from "./Components/Pages/Slides.jsx";
import Models from "./Components/Pages/Models.jsx";
import Organs from "./Components/Pages/Organs.jsx";
import Users from "./Components/Pages/Users.jsx";
import Pipelines from "./Components/Pages/Pipelines.jsx";
import Reviewers from "./Components/Pages/ReviewPipelines";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { retry: false }
	}
});

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      access_token: Cookies.get('deepstain-access') || undefined,
    }
  }

  render() {
    const {access_token} = this.state
    return (
      <>
        <QueryClientProvider client={ queryClient }>
          <Router>
            <Switch>
              {/* AUTH ROUTES */}
              <Route path="/signin">
                {access_token !== undefined ? <Redirect to="/" /> : <SignIn />}
              </Route>
              <Route path="/dashboard">
                {access_token === undefined ? <Redirect to="/" /> : <Dashboard />}
              </Route>
              <Route path="/studies">
                {access_token === undefined ? <Redirect to="/" /> : <Studies />}
              </Route>
              <Route path="/models">
                {access_token === undefined ? <Redirect to="/" /> : <Models />}
              </Route>
              <Route path="/pipelines">
                {access_token === undefined ? <Redirect to="/" /> : <Pipelines />}
              </Route>
              <Route path="/organs">
                {access_token === undefined ? <Redirect to="/" /> : <Organs />}
              </Route>
              <Route path="/slides">
                {access_token === undefined ? <Redirect to="/" /> : <Slides />}
              </Route>
              <Route path="/reviewers">
                {access_token === undefined ? <Redirect to="/" /> : <Reviewers />}
              </Route>
              <Route path="/users">
                {access_token === undefined ? <Redirect to="/" /> : <Users />}
              </Route>
              <Route exact path="/">
                {access_token !== undefined ? <Redirect to="/dashboard"/> : <Home />}
              </Route>
            </Switch>
          </Router>
        </QueryClientProvider>
      </>
  );}
}
