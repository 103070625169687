import {API_URL} from '../config'
import { routes } from './constants';


const _create = async (token, body, obj_route) => {
    const bearer = `Bearer ${token}`;   
    const server_url = `${API_URL}/v1/${obj_route}/`
    
    const r = await fetch(server_url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': bearer
        },
        body: JSON.stringify(body)
    });
    return r;
}

const _read = async (token, obj_route, limit=100, page=1) => {    
  const bearer = `Bearer ${token}`;   
  const server_url = `${API_URL}/v1/${obj_route}?limit=${limit}&page=${page}`
  const rawResponse = await fetch(server_url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Authorization': bearer
    }
  });
  let results = await rawResponse.json()
  return results
}

const _readById = async (token, obj_route, idx) => {    
  const bearer = `Bearer ${token}`;   
  const server_url = `${API_URL}/v1/${obj_route}/${idx}`
  const rawResponse = await fetch(server_url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json', 
      'Authorization': bearer
    }
  });
  let results = await rawResponse.json()
  if (results.length === 0)
    return;
  return results
}

const _updateById = async (token, obj_route, idx, body) => {    
  const bearer = `Bearer ${token}`;   
  const server_url = `${API_URL}/v1/${obj_route}/${idx}`
  console.log(server_url)
  const rawResponse = await fetch(server_url, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json', 
      'Authorization': bearer
    }, 
    body: JSON.stringify(body)
  });
  let results = await rawResponse.json()
  if (results.length === 0)
    return;
  return results
}

const _updateById_dontAssignUndefined = async (token, obj_route, idx, body) => { // TODO: update the original function
  const bearer = `Bearer ${token}`;   
  const server_url = `${API_URL}/v1/${obj_route}/${idx}`
  console.log(server_url)
  const rawResponse = await fetch(server_url, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json', 
      'Authorization': bearer
    }, 
    body: JSON.stringify(body)
  });
  return rawResponse;
}

const _deleteById = async (token, obj_route, idx) => {    
  const bearer = `Bearer ${token}`;   
  const server_url = `${API_URL}/v1/${obj_route}/${idx}`
  const rawResponse = await fetch(server_url, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json', 
      'Authorization': bearer
    }
  });
  return rawResponse
}

const _runPipeline = async (token, body) => {
  const bearer = `Bearer ${token}`;
  console.log(body)
  const r = await fetch(`${API_URL}/v1/slides/pipe`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': bearer
    },
    body: JSON.stringify(body)
  });
  return r
}

const _addCollectionToStudy = async (token, body) => {
  const bearer = `Bearer ${token}`;
  const r = await fetch(`${API_URL}/v1/studies/add/c`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': bearer
    },
    body: JSON.stringify(body)
  });
  return r
}

const _addAssigneeToStudy = async (token, body) => {
  const bearer = `Bearer ${token}`;
  const r = await fetch(`${API_URL}/v1/studies/add/as`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': bearer
    },
    body: JSON.stringify(body)
  });
  return r
}

const _addAssigneeToOrgan = async (token, body) => {
  const bearer = `Bearer ${token}`;
  const r = await fetch(`${API_URL}/v1/organs/a/add`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': bearer
    },
    body: JSON.stringify(body)
  });
  return r
}

const _removeAssigneeFromOrgan = async (token, body) => {
  const bearer = `Bearer ${token}`;
  const r = await fetch(`${API_URL}/v1/organs/a/remove`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': bearer
    },
    body: JSON.stringify(body)
  });
  return r
}

const _removeAssigneeFromStudy = async (token, body) => {
  const bearer = `Bearer ${token}`;
  const r = await fetch(`${API_URL}/v1/studies/remove/as`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': bearer
    },
    body: JSON.stringify(body)
  });
  return r
}

const _startReviewPipeline = async (token, reviewPipelineID) => {
  const bearer = `Bearer ${token}`;
  const r = await fetch(`${API_URL}/v1/${routes.reviewPipelines}/s/${reviewPipelineID}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': bearer
    }    
  });
  if (!r.ok) {
    console.log("r", r);
    return;
  }
  const res = await r.json();
  return res;
}

// const _getNotes = async (sid, token) => {
//   const bearer = `Bearer ${token}`;   
//   const server_url = `${API_URL}/v1/notes/for/${sid}`
//   const rawResponse = await fetch(server_url, {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json, text/plain, */*',
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     }
//   });
//   const notes = await rawResponse.json()
//   if (notes.length === 0)
//     return;
//   return notes
// } 

// const _getStudies = async (token) => {
//     const bearer = `Bearer ${token}`;   
//     const server_url = `${API_URL}/v1/studies`
//     const rawResponse = await fetch(server_url, {
//     method: 'GET',
//     headers: {
//         'Accept': 'application/json, text/plain, */*',
//         'Content-Type': 'application/json', 
//         'Authorization': bearer
//     }
//     });
//     let studies = await rawResponse.json()
//     if (studies.length === 0)
//         return;
//     return studies;
// }

// const _getStudyById = async (idx, token) => {
//     const bearer = `Bearer ${token}`;   
//     const server_url = `${API_URL}/v1/studies/${idx}`
//     const rawResponse = await fetch(server_url, {
//       method: 'GET',
//       headers: {
//         'Accept': 'application/json, text/plain, */*',
//         'Content-Type': 'application/json', 
//         'Authorization': bearer
//       }
//     });
//     let study = await rawResponse.json()
//     return study; 
// }

// const _getCollectionById = async (idx, token) => {
//     const bearer = `Bearer ${token}`;   
//     const server_url = `${API_URL}/v1/collections/${idx}`
//     const rawResponse = await fetch(server_url, {
//       method: 'GET',
//       headers: {
//         'Accept': 'application/json, text/plain, */*',
//         'Content-Type': 'application/json', 
//         'Authorization': bearer
//       }
//     });
//     let collection = await rawResponse.json()
//     return collection
// }

// const _getPredictionById = async (idx, token) => {
//     const bearer = `Bearer ${token}`;   
//     const server_url = `${API_URL}/v1/predictions/${idx}`
//     const rawResponse = await fetch(server_url, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json', 
//         'Authorization': bearer
//       }
//     });
//     const prediction = await rawResponse.json()
//     return prediction;
// }

const _getSimilarByInImg = async (in_img, token, study_id=undefined, collection_id=undefined) => {
    const bearer = `Bearer ${token}`;   
    const server_url = `${API_URL}/v1/predictions/gs/${in_img}?study_id=${study_id}`
    const rawResponse = await fetch(server_url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': bearer
      }
    });
    const p = await rawResponse.json()
    return p;
}

// const _getNoteById = async (idx, token) => {
//     const bearer = `Bearer ${token}`;   
//     const server_url = `${API_URL}/v1/notes/${idx}`
//     const rawResponse = await fetch(server_url, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json', 
//         'Authorization': bearer
//       }
//     });
//     const note = await rawResponse.json()
//     return note;
// }

// const _getSlideById = async (idx, token) => {
//   const bearer = `Bearer ${token}`;   
//   const server_url = `${API_URL}/v1/slides/${idx}`
//   const rawResponse = await fetch(server_url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     }
//   });
//   const note = await rawResponse.json()
//   return note;
// }

// const _getPipelineById = async (idx, token) => {
//   const bearer = `Bearer ${token}`;   
//   const server_url = `${API_URL}/v1/pipelines/${idx}`
//   const rawResponse = await fetch(server_url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     }
//   });
//   const note = await rawResponse.json()
//   return note;
// }

// const _getPredictionById = async (idx, token) => {
//   const bearer = `Bearer ${token}`;   
//   const server_url = `${API_URL}/v1/predictions/${idx}`
//   const rawResponse = await fetch(server_url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     }
//   });
//   const note = await rawResponse.json()
//   return note;
// }

// const _getPredictorById = async (idx, token) => {
//   const bearer = `Bearer ${token}`;   
//   const server_url = `${API_URL}/v1/predictions/${idx}`
//   const rawResponse = await fetch(server_url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     }
//   });
//   const note = await rawResponse.json()
//   return note;
// }

// const _getUserById = async (idx, token) => {
//   const bearer = `Bearer ${token}`;   
//   const server_url = `${API_URL}/v1/predictions/${idx}`
//   const rawResponse = await fetch(server_url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     }
//   });
//   const note = await rawResponse.json()
//   return note;
// }

// const _updateCollectionById = async (idx, token, body) => {
//     const bearer = `Bearer ${token}`;   
//     const server_url = `${API_URL}/v1/studies/${idx}`
    
//     await fetch(server_url, {
//         method: 'PATCH',
//         headers: {
//             'Accept': 'application/json, text/plain, */*',
//             'Content-Type': 'application/json', 
//             'Authorization': bearer
//         },
//         body: JSON.stringify(body)
//     });
// }

// const _updateNoteById = async (idx, token, body) => {
//   const bearer = `Bearer ${token}`;
//   const server_url = `${API_URL}/v1/notes/${idx}`
//   await fetch(server_url, {
//     method: 'PATCH',
//     headers: {
//       'Accept': 'application/json, text/plain, */*',
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     },
//     body: JSON.stringify(body)
//   });
// }

// const _updateStudyById = async (idx, token, body) => {
//   const bearer = `Bearer ${token}`;
//   const server_url = `${API_URL}/v1/studies/${idx}`
//   await fetch(server_url, {
//     method: 'PATCH',
//     headers: {
//       'Accept': 'application/json, text/plain, */*',
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     },
//     body: JSON.stringify(body)
//   });
// }

// const _updateSlideById = async (idx, token, body) => {
//   const bearer = `Bearer ${token}`;
//   const server_url = `${API_URL}/v1/studies/${idx}`
//   await fetch(server_url, {
//     method: 'PATCH',
//     headers: {
//       'Accept': 'application/json, text/plain, */*',
//       'Content-Type': 'application/json', 
//       'Authorization': bearer
//     },
//     body: JSON.stringify(body)
//   });
// }

export {
    _create,
    _read,
    _runPipeline, 
    _addCollectionToStudy,
    _addAssigneeToStudy,
    _addAssigneeToOrgan,
    _removeAssigneeFromOrgan,
    _readById, 
    _updateById,
    _updateById_dontAssignUndefined,
    _deleteById, 
    _removeAssigneeFromStudy,
    _startReviewPipeline,
    // _getStudies, 
    // _getNotes,
    // _getCollections,
    // _getSlides,
    // _getPipelines,
    // _getPredictions,
    // _getPredictors,
    // _getUsers,

    // _getStudyById, 
    // _getNoteById,
    // _getCollectionById, 
    // _getPredictionById, 
    _getSimilarByInImg,
    // _getSlideById,
    // _getPipelineById,
    // _getPredictionById,
    // _getPredictorById,
    // _getUserById,
    
    // _updateNoteById,
    // _updateCollectionById, 
    // _updateStudyById, 
    // _updateSlideById
  };
