import React, {Component} from 'react'
import {Image, Button, Form, Grid, Header, Segment, Loader, Message } from 'semantic-ui-react'
import Cookies from 'js-cookie';
import {Redirect} from 'react-router-dom'
import {API_URL} from '../../config.jsx'
import SignWP from '../../Assets/signWP.png'
import logo from '../../Assets/pictornavbar.png'


export class LoginForm extends Component {
  state = {email: "", password: "", redirect: false, loggingIn: false, error: false, errorMsg: undefined}
 
  handleChange = (e, { name, value }) => this.setState({ [name]: value })
  componentDidMount(){
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount(){
    document.body.style.overflow = "auto";
  }

  addMonths = (months) => {
    var myCurrentDate=new Date();
    var myFutureDate=new Date(myCurrentDate);
    myFutureDate.setDate(myFutureDate.getDate()+ months*31);
    return myFutureDate
  }

  handleLogin = async () => {
    const {email, password} = this.state
    const data = {
      email: email,
      password: password
    } 
    const url = `${API_URL}/v1/auth/login`
    this.setState({
      loggingIn: true
    })
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    const res = await response.json()
    const status = response.status
    if (status === 200) {
      Cookies.set('deepstain-useremail', res.user.email)
      Cookies.set('deepstain-access', res.tokens.access, {expires: this.addMonths(1)})
      Cookies.set('deepstain-refresh', res.tokens.refresh)
        this.setState({
          redirect: true
        })
        window.location.reload();
    }

    this.setState({
      loggingIn: false
    })
    this.setState({
      error: true, 
      errorMsg: res.message
    })
   return;
  } 

  render() {
    const {email, password, redirect, loggingIn, error, errorMsg} = this.state
    return(
      redirect ? <Redirect to="/" /> :  
      <div style={{
          backgroundImage: `url(${SignWP})`, 
          backgroundRepeat: 'no-repeat', 
          backgroundSize: 'auto', 
          backgroundColor: '#ECDFF0', 
          height:'120vh'}}>
        <Grid size="small" textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
          <Grid.Column as={Segment} style={{ maxWidth: '60vh', maxHeight: '100vh' }}>
              <div style={{padding: '3vh'}}>
                <Header > 
                <Image style={{width: '40%', height: '40%'}} size="massive" src={logo}/>
                </Header>
              </div>
              <Grid.Row>
                <div style={{paddingTop: '2vh', paddingLeft: '8vh', paddingRight: '8vh'}}>
                  {error && errorMsg !== undefined && 
                        <div style={{paddingBottom: '2vh'}}><Message
                            error
                            header={`Login Error: ${errorMsg}`}
                        /> </div>}
                    {!loggingIn && <div>
                      <Form size='big'>
                        <Form.Input name='email'
                          value={email}
                          onChange={this.handleChange}
                          icon="user" fluid iconPosition='left' placeholder='E-mail address' />
                          <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Password'
                            type='password'
                            name='password'
                            value={password}
                            onChange={this.handleChange}
                          />
                          <Button onClick={this.handleLogin} fluid size='big'>
                            Sign in
                          </Button>
                          </Form></div>}
                    {loggingIn && <div style={{padding: '5vh'}}><Loader active inline='centered' /></div>}
                </div>
              </Grid.Row>

            </Grid.Column>
        </Grid>
         
      </div>
      )
  }
 
}

export default LoginForm