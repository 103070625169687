import Cookies from "js-cookie";
import { _read } from "Lib/canvas";
import { routes } from "Lib/constants";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const ReviewPipelineContext = React.createContext({});

export const useReviewPipelines = () => React.useContext(ReviewPipelineContext);

export default function ReviewPipelineProvider({ children }) {
  const [reviewPipelines, setReviewPipelines] = useState([]);
  const reviewPipelineQuery = useQuery(
    routes.reviewPipelines, () => {
      const token = JSON.parse(Cookies.get("deepstain-access")).token;
      return _read(token, routes.reviewPipelines);
  }, { refetchOnWindowFocus: false, refetchInterval: 0 });

  useEffect(() => {
    if (reviewPipelineQuery.data) {
      setReviewPipelines(reviewPipelineQuery.data.results);
    }
  }, [reviewPipelineQuery.data]);

  return (
    <ReviewPipelineContext.Provider value={ { reviewPipelines, setReviewPipelines, reviewPipelineQuery } }>
      { children }
    </ReviewPipelineContext.Provider>
  )
}
