import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import {Button, Container} from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import NavBar from './NavBar'
import Footer from './Utils/Footer'
import Stain from './AdminControls/Stain';
import Invite from './AdminControls/Invite';
import UploadImage from './AdminControls/UploadImage'
import AddToStudyModal from './AdminControls/AddToStudyModal'
import AddToOrganModal from './AdminControls/AddToOrganModal';
import DeleteOrganModal from './AdminControls/DeleteOrganModal';
import RemoveUserFromStudy from './AdminControls/RemoveUserFromStudy';
class CollectionDash extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      token: JSON.parse(
        Cookies.get("deepstain-access")).token,
      profileControl: false
    }
    window.document.body.style.overflow = "hidden"
  }

  toggleProfileControl = () => {
    const {profileControl} = this.state;
    this.setState({profileControl: !profileControl})
  }


  render () {
    const {profileControl} = this.state;
    const panelsOff = e => {
      this.setState({
        profileControl: false})
    }
    return (
      <div>
        <NavBar
          profileControl={profileControl} 
          toggleProfileControl={this.toggleProfileControl} />
        {<div>
          <Container
            blurring 
            onClick={panelsOff}
            style={{
              paddingTop: '2vh', 
              paddingLeft: '0.5vh', 
              height: '82vh'}}>
              <AddToStudyModal/>
              <Button.Group>
                <AddToOrganModal/>
                <DeleteOrganModal/>
                <RemoveUserFromStudy/>
              </Button.Group>
              <Stain/>
              <UploadImage/>
              <Invite/>
          </Container>
          <Footer/>
        </div>}
      </div>
    );
  }
}

export default withRouter(CollectionDash);
