import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import {
  Header, Button, 
  Input, Modal, 
  Icon} from 'semantic-ui-react'
import 'react-dropzone-uploader/dist/styles.css'
import { _create } from 'Lib/canvas';


class NewModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      token: JSON.parse(Cookies.get("deepstain-access")).token,    
      createOpen: false,
      temp_obj_name: ""
    }
  }

  createObj = async () => {
    const {route_obj} = this.props;
    const {token, temp_obj_name} = this.state;
    _create(
      token, 
      { name: temp_obj_name },
      route_obj)
    this.setState({createOpen: false})
  }

  render () {
    const {
      createOpen, 
      isCreateLoading} = this.state;
    const {
      obj_name
    } = this.props;
    return ( <div>
        {<div style={{paddingBottom: '4vh'}}> 
            <Button.Group>
              <Modal
                  onClose={() => this.setState({createOpen: false})}
                  onOpen={() => this.setState({createOpen: true})}
                  open={createOpen}
                  trigger={
                      <Button 
                          size="big" 
                          icon 
                          labelPosition='left'>
                          <Icon name='clone outline' />
                          New {obj_name}
                      </Button>}>
                  <div style={{padding: '3vh'}}>
                      <Header>{obj_name} Name </Header>
                      <Input onChange={(e, s) => {
                              this.setState({temp_obj_name: s.value})}} 
                            fluid 
                            size="big"
                            placeholder={`Enter a name for your new ${obj_name}`} />
                  </div>
                    <Modal.Actions>
                        <Button             
                            content="Cancel"
                            labelPosition='right'
                            icon='times' color='red' 
                            onClick={() => this.setState(
                                {createOpen: false})}>
                        </Button>
                        <Button
                            loading={isCreateLoading}
                            content="Create"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => this.setState(
                                {createOpen: false} && this.createObj())}
                            positive
                        />
                    </Modal.Actions>
                  </Modal>

            </Button.Group>

        </div>
        }   
        </div>
    );
  }
}

export default NewModal;
