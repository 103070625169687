import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, Icon, Header, List, Loader } from 'semantic-ui-react'
import { _read } from '../../Lib/canvas';
import Cookies from 'js-cookie';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: grid * 2,
	margin: `0 ${grid}px 0 0`,
	// change background colour if dragging
	background: isDragging ? '#0895FF' : 'grey',
	color: 'white',
	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? 'lightblue' : 'lightgrey',
	display: 'flex',
	padding: grid,
	overflow: 'auto',
});

class DraggableList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: this.props.items,
			items_available: [],
			open: false,
			obj_route: this.props.obj_route
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	getAvailableItems = async () => {
		if (this.state.items_available.length === 0) {
			const response = await _read(
				JSON.parse(
					Cookies.get("deepstain-access")).token,
				this.state.obj_route)
			this.setState({ items_available: response.results })
		}
	}
	
	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index
		);

		if (this.props.onDragEnd) { this.props.onDragEnd(items); }
		this.setState({
			items,
		});
	}

	deleteItem = async (index) => {
		if (window.confirm(`delete foreal?`)) {
			const items = this.state.items;
			items.splice(index, 1);
			this.setState({ items: items })
			this.props.onDragEnd(items)
		}
	}

	addItem = async (item) => {
		let new_items = this.state.items;
		new_items.push({
			content: this.props.obj_content(item),
			id: item.id,
			status: 0
		})
		this.setState({ items: new_items, open: false })
		this.props.onDragEnd(new_items)
	}

	render() {
		const { items_available, open } = this.state;
		return (
			<DragDropContext onDragEnd={ this.onDragEnd }>
				<Droppable droppableId="droppable" direction="horizontal">
					{ (provided, snapshot) => (
						<div
							ref={ provided.innerRef }
							style={ getListStyle(snapshot.isDraggingOver) }
							{ ...provided.droppableProps }
						>
							{ this.state.items.map((item, index) => (
								<div>
									<Draggable as={ Button } key={ item.id } draggableId={ item.id } index={ index }>
										{ (provided, snapshot) => (
											<div
												onDoubleClick={
													(e) => {
														e.preventDefault();
														if (e.detail === 2) {
															this.deleteItem(index);
														}
													} }
												ref={ provided.innerRef }
												{ ...provided.draggableProps }
												{ ...provided.dragHandleProps }
												style={ getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												) }>
												{ item.content }
											</div>
										) }
									</Draggable>
								</div>
							)) }
							{
								<Modal
									basic
									onClose={ () => this.setState({ open: false }) }
									onOpen={ () => { this.setState({ open: true }); this.getAvailableItems() } }
									open={ open }
									size='small'
									trigger={ <Button icon><Icon name="plus" /></Button> }
								>
									<Header icon>
										<Icon name='plus' />
										{ this.props.addItemMenuHeader }
									</Header>
									<Modal.Content>
										<p>
											{ this.props.addItemMenuDescription }
										</p>
										<List selection verticalAlign='middle'>
											<Loader disabled={items_available.length !== 0} inline='centered' />
											{ items_available && items_available.map((item) =>
												<List.Item onClick={ () => {
													this.addItem(item);
												} }>
													<List.Content>
														<List.Header style={ { color: 'white' } }>{ this.props.obj_content(item) }</List.Header>
													</List.Content>
												</List.Item>) }
										</List>
									</Modal.Content>
								</Modal>
							}
							{ provided.placeholder }

						</div>
					) }
				</Droppable>
			</DragDropContext>
		);
	}
}

// Put the thing into the DOM!
export default DraggableList;
