import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import { Header, Item, Button, Segment, Loader, Dropdown, Message, Input, Dimmer } from 'semantic-ui-react'
import { _startReviewPipeline, _create, _updateById_dontAssignUndefined, _deleteById } from 'Lib/canvas';
import 'App.css';
import { routes } from 'Lib/constants';
import { Timeline } from '@mantine/core';
import { GitCommit } from 'tabler-icons-react';
import { MultiSelect } from '@mantine/core';

function ReviewPipelineLayer({ layer, onChange, userOptions }) {
	const [reviewers, setReviewers] = React.useState(layer.reviewers);

	React.useEffect(() => {
		setReviewers(layer.reviewers);
	}, [layer.reviewers])

	const options = React.useMemo(() => {
		return userOptions.map(user => ({
			value: user.id,
			label: user.email
		}))
	}, [userOptions]);

	/* we use this to remember the "reviewedAt" */
	const reviewerIDtoReviewer = React.useMemo(() => {
		return reviewers?.reduce((acc, reviewer) => {
			acc[reviewer.userID] = reviewer;
			return acc;
		}, {}) ?? {};
	}, [reviewers]);

	const userIDtoReviewer = React.useMemo(() => {
		return userOptions.reduce((acc, user) => {
			const reviewer = {
				userID: user.id,
				reviewedAt: reviewerIDtoReviewer[user.id]?.reviewedAt // remember the "reviewedAt"
			}
			acc[user.id] = reviewer;
			return acc;
		}, {})
	}, [reviewerIDtoReviewer, userOptions]);

	const handleChange = React.useCallback((value) => {
		const _reviewers = value.map(userID => userIDtoReviewer[userID]);
		const _layer = { ...layer, reviewers: _reviewers };

		setReviewers(_reviewers);
		onChange(_layer);
	}, [layer, onChange, userIDtoReviewer]);

	return (
		<Item.Group>
			<Item>
				<label>Reviewers:</label>
				<MultiSelect searchable value={ reviewers.map((reviewer) => reviewer.userID) }
					onChange={ handleChange } data={ options } />
			</Item>
			<Item>
				<label>Reviewed at:</label>
				<span>{ layer.reviewedAt || 'never' }</span>
			</Item>
			<Item>
				<label>Reviewed Collections:</label>
				{ layer.reviewedCollections?.length || 0 }
				{/* { reviewedCollections && reviewedCollections.map(collection =>
					<Label>{collection}</Label>
				) } */}
			</Item>

		</Item.Group>
	)
}

const reviewPipelineStatuses = { // looking forward to typescript lol
	"-2": "archived",
	"-1": "not started",
	"0": "started",
	"1": "completed"
}

const statusStyles = {
	"-2": { backgroundColor: 'hsl(0deg 67% 82%)', borderColor: '#f0f0f0' }, // archived, red
	"-1": { backgroundColor: 'hsl(53deg 67% 82%)' }, // not started, yellow
	"0": { backgroundColor: 'hsl(115deg 67% 82%)' }, // started, green
	"1": { backgroundColor: 'hsl(0deg 0% 57%)' } // completed, gray
}

export default function ReviewPipeline({ rpipe = {}, studyIDtoStudy = {}, studyOptions = [], userOptions = [] }) {
	const [reviewPipeline, setReviewPipeline] = React.useState(rpipe);
	const [reviewPipelineSnapshot, setReviewPipelineSnapshot] = React.useState(rpipe); // used to determine if the reviewPipeline has changed
	const [removed, setRemoved] = React.useState(false);
	const [updating, setUpdating] = React.useState(false);

	const { name, studyID, status, createdBy, layers } = React.useMemo(() => {
		return reviewPipeline;
	}, [reviewPipeline]);
	const [study, setStudy] = React.useState(studyIDtoStudy[studyID]);

	const modified = React.useMemo(() => {
		return reviewPipeline !== reviewPipelineSnapshot;
	}, [reviewPipeline, reviewPipelineSnapshot])

	React.useEffect(() => {
		setStudy(studyIDtoStudy[studyID]);
	}, [reviewPipeline, studyID, studyIDtoStudy]);

	const sanitizeReviewPipeline = React.useCallback((reviewPipeline) => {
		delete reviewPipeline.status; // status is not allowed when creating the review pipeline. It will be assigned -1 (not started)
		delete reviewPipeline.createdBy;
		delete reviewPipeline.reviewedAt;
		delete reviewPipeline.reviewersToLayer;

		reviewPipeline.layers = reviewPipeline.layers.map(layer => {
			delete layer.reviewedCollections;
			delete layer.reviewedAt;
			layer.reviewers = layer.reviewers.map(reviewer => {
				delete reviewer.reviewedAt;
				return reviewer;
			});
			return layer;
		});
		return reviewPipeline;
	}, []);

	const createReviewPipeline = React.useCallback(async () => {
		const token = JSON.parse(Cookies.get("deepstain-access")).token;
		
		const _reviewPipeline = sanitizeReviewPipeline(reviewPipeline);
		const res = await _create(token, _reviewPipeline, routes.reviewPipelines);
		if (!res.ok) {
			window.alert((await res.json()).message);
			return;
		}
		const newReviewPipeline = await res.json();
		setReviewPipeline(newReviewPipeline);
		setReviewPipelineSnapshot(newReviewPipeline);
	}, [reviewPipeline, sanitizeReviewPipeline]);

	const updateReviewPipeline = React.useCallback(async () => {
		setUpdating(true);
		const token = JSON.parse(Cookies.get("deepstain-access")).token;
		const _reviewPipeline = { ...reviewPipeline, status: undefined };
		const res = await _updateById_dontAssignUndefined(token, routes.reviewPipelines, reviewPipeline.id, _reviewPipeline);
		if (!res.ok) {
			window.alert((await res.json()).message);
			setUpdating(false);
			return;
		}
		const updatedReviewPipeline = await res.json();
		setReviewPipeline(updatedReviewPipeline);
		setReviewPipelineSnapshot(updatedReviewPipeline);
		setUpdating(false);
	}, [reviewPipeline]);

	const startReviewPipeline = React.useCallback(async () => {
		if (!window.confirm("Are you sure you want to start this review pipeline?"
			+ "\n You will not be able to modify layers or the study afterwards."))
			return;

		setUpdating(true);
		const token = JSON.parse(Cookies.get("deepstain-access")).token;
		const startedReviewPipeline = await _startReviewPipeline(token, reviewPipeline.id);
		if (startedReviewPipeline) {
			setReviewPipeline(startedReviewPipeline);
			setReviewPipelineSnapshot(startedReviewPipeline);
		}
		else window.alert("Could not start review pipeline");
		setUpdating(false);
	}, [reviewPipeline]);

	const removeReviewPipeline = React.useCallback(async () => {
		if (!window.confirm('Are you sure you want to delete this review pipeline?'))
			return;
		if (reviewPipeline.id === undefined) {
			setRemoved(true);
			return;
		}
		const token = JSON.parse(Cookies.get("deepstain-access")).token;
		const resp = await _deleteById(token, routes.reviewPipelines, reviewPipeline.id);
		if (resp.status === 204) {
			setRemoved(true);
		}
	}, [reviewPipeline]);

	const resetChanges = React.useCallback(() => {
		setReviewPipeline(reviewPipelineSnapshot);
	}, [reviewPipelineSnapshot]);

	const postButton = React.useMemo(() => {
		const isNew = reviewPipeline.id === undefined;
		const content = isNew ? 'Create New' : 'Save';
		const disabled = !(
			['name', 'studyID', 'layers'].every((x) => x in reviewPipeline) // all required fields are present
			&& reviewPipeline.layers.every((layer) => layer.reviewers.length > 0) // all layers have reviewers
		)
		return (
			<Button compact color='green' icon='right arrow' content={ content } labelPosition='right'
				disabled={ disabled }
				style={ { float: 'right' } }
				onClick={ isNew ? createReviewPipeline : updateReviewPipeline }>
			</Button>
		)
	}, [createReviewPipeline, reviewPipeline, updateReviewPipeline]);


	const handleReviewPipelineChange = React.useCallback((changes) => {
		setReviewPipeline((rpipe) => { return { ...rpipe, ...changes } });
	}, []);

	const studyDropdown = React.useMemo(() => {
		return (
			<Dropdown placeholder='Select study' fluid selection search compact
				options={ studyOptions.map(
					(study) => ({
						key: study.id,
						value: study.id,
						text: study.name
					})
				) }
				value={ study?.id }
				text={ study !== undefined && `${study.name ?? "name not available"} (${study.id})` }
				onChange={ (e, { value }) => {
					handleReviewPipelineChange({ studyID: value });
				} }
			/>
		)
	}, [handleReviewPipelineChange, study, studyOptions]);

	const handleLayerChange = React.useCallback((item, i) => {
		setReviewPipeline((rpipe) => {
			let layers = [...rpipe.layers];
			layers[i] = item;
			return { ...rpipe, layers };
		});
	}, []);

	const addLayer = React.useCallback(() => {
		setReviewPipeline((rpipe) => {
			let layers = [...rpipe.layers];
			layers.push({
				reviewers: []
			});
			return { ...rpipe, layers };
		});
	}, []);

	// if (updating) {
	// 	return <Loader active inline='centered'>asdfasdfasdf</Loader>
	// }

	if (removed) {
		return <Message warning>
			<Message.Header>Removed: { name }</Message.Header>
			<p>The review pipeline "{ name }" has been removed.</p>
		</Message>
	}

	return (
		<Segment>
			{updating && (
				<Dimmer active inverted>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
			)}
			<style jsx>{ `
				label { margin-right: 1em; }
				.reviewPipelineTitle * { font-weight: bold; }
				.reviewPipelineTitle:not(:focus-within) * {
					border: none !important;
				}
			`}</style>
			<Header>
				<div className='flex-row'>
					<label style={{fontWeight: 'normal' }} >name</label>
					<Input className='reviewPipelineTitle' placeholder='Search...' value={ name }
						onChange={ (e, { value }) => handleReviewPipelineChange({ name: value }) } />
				</div>
			</Header>
			<Item.Group>
				<Item className='flex-row'>
					<label>Study</label>
					{ studyDropdown }
				</Item>

				<Item className='flex-row'>
					<label>Status</label>
					<Dropdown placeholder='Select status' fluid selection search compact
						style={ { ...(statusStyles[status] ?? {}) } }
						// disabled={ reviewPipeline.id === undefined }
						selectOnBlur={ false }
						options={ Object.keys(reviewPipelineStatuses).map(
							(status) => ({
								key: status,
								value: status,
								text: `${status} (${reviewPipelineStatuses[status]})`,
								// // only allow starting, for now. // TODO: add archive functionality
								// disabled: (reviewPipeline.id === undefined || (status !== '0' && status !== rpipe.status.toString()))
								disabled: true // starting is done using the start button
							})
						) }
						// value={ status }
						text={ `${status} (${reviewPipelineStatuses[status]})` }
						defaultValue={ status }
						onChange={ (e, { value }) => {
							handleReviewPipelineChange({ status: value });
						} }
					/>
					{ reviewPipeline.id !== undefined && status !== 0 && // TODO: use enum when we have typescript
						<Button compact color='green' icon='right arrow' content='Start' labelPosition='right'
							style={ { marginLeft: '1em' } }
							onClick={ startReviewPipeline }
						/> }
				</Item>

				{ createdBy && <Item>
					<label>Created By</label>
					<span>{ userOptions.find((user) => user.id === createdBy)?.email ?? createdBy }</span>
				</Item> }

				<Item>
					<label>Layers</label>
					<div className='flex-col'>
						<Timeline bulletSize={ 24 } lineWidth={ 2 }>
							{ layers && layers.map((layer, i) =>
								<Timeline.Item bullet={ <GitCommit size={ 12 } /> } title={ `layer ${i + 1}` }>
									<div style={ { paddingTop: '1em' } }>
										<ReviewPipelineLayer
											layer={ layer }
											onChange={ (item) => handleLayerChange(item, i) }
											userOptions={ userOptions } />
									</div>
								</Timeline.Item>
							) }
						</Timeline>
						<Button style={ { margin: '1em' } }
							compact icon='plus' content='Add Layer' onClick={ addLayer }
						/>
					</div>
				</Item>
			</Item.Group>
			{ (modified || reviewPipeline.id === undefined) && postButton }
			{ (modified) && <Button
				compact icon='cancel' color='yellow' content='Cancel' onClick={ resetChanges }
			/> }
			{ !removed && <Button compact color='red' icon='trash' content='Delete' labelPosition='right'
				style={ { float: 'right' } }
				onClick={ () => {
					removeReviewPipeline(reviewPipeline.id);
				} }
			/> }
		</Segment>
	);
}
