import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import { Label, Header, Table, Button, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { _read, _deleteById } from '../../Lib/canvas'


class ListObj extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			objs: [],
			token: JSON.parse(
				Cookies.get("deepstain-access")).token,
			pages: 1
		}
		window.document.body.style.overflow = "hidden"
	}

	componentDidMount() {
		this.getObjs()
	}

	getObjs = async () => {
		const { obj_route, results } = this.props;
		let { token, pages, objs } = this.state;
		const alls = await _read(token, obj_route, 100, pages)
		if (alls.totalPages >= pages) {
			pages++;
		}
		else if (obj_route !== "studies") {
			return;
		}
		let r = undefined;
		if (results) {
			r = await alls.results;
		} else {
			r = await alls;
		}
		r.map((item) => objs.push(item))

		this.setState({ objs: objs, pages: pages })
	}

	delObj = async (obj_id) => {
		const { obj_route } = this.props;
		const { token } = this.state;
		await _deleteById(token, obj_route, obj_id)
		this.getObjs()
	}


	render() {
		const { objs } = this.state;
		return (
			<div>
				{ <div style={ { maxHeight: '65vh', overflowY: 'auto' } } >
					<Table>
						<Table.Body>
							{ objs !== undefined && objs.map((obj) =>
								<div>
									<Table.Row>
										{ Object.keys(obj).sort().map((key, i) => (
											<Table.Cell>
												<p key={ i }>
													<Header>{ key }</Header>
													{ (typeof obj[key] == "string" || typeof obj[key] == "number") &&
														<Label>{ obj[key] }</Label> }
													{ Array.isArray(obj[key]) && obj[key].map((arr_obj) =>
														<Label color={ String(arr_obj) === "null" ? "red" : "" }
														>{ `${String(arr_obj).substring(0, 5)}...` }</Label>) }
												</p>
											</Table.Cell>)) }
										<div style={ { margin: '5vh', } }>
											<Button onClick={ () => { this.delObj(obj.id) } } color="red" icon circular>
												<Icon name="x" />
											</Button>
										</div>
									</Table.Row>
								</div>
							) }
						</Table.Body>
					</Table>
					<Button onClick={ this.getObjs } circular fluid color="blue">Load More</Button>
				</div> }
			</div>
		);
	}
}

export default withRouter(ListObj);
