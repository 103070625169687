import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import {
  Header, Button, Modal, Icon, Card} from 'semantic-ui-react'
import 'react-dropzone-uploader/dist/styles.css'
import { _addAssigneeToStudy, _read } from '../../Lib/canvas';


class AddToStudyModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      token: JSON.parse(Cookies.get("deepstain-access")).token,    
      createOpen: false,
      temp_study: undefined, 
      temp_user: undefined,
      studies: [], 
      users: []
    }
  }

  componentDidMount(){
    this.getStudies();
    this.getUsers();
  }

    getStudies = async () => {
        const {token} = this.state
        const s = await _read(token, "studies")
        const res = s

        if (res.length === 0)
          return;
        const sOptions = []
        for (let i = 0; i < res.length; i++){
          const mn = res[i].name
          const mid = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mid
          }
          sOptions.push(t)
        }
        this.setState({studies: sOptions})
    }

    getUsers = async () => {
        const {token} = this.state
        let s = await _read(token, "users", )
        const res = s.results
        s = await _read(token, "users", s.totalResults)
        if (res.length === 0)
          return;
        const sOptions = []
        for (let i = 0; i < res.length; i++){
          const mn = res[i].email
          const mid = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mid
          }
          sOptions.push(t)
        }
        this.setState({users: sOptions})
    }

  addAssigneeToStudy = async () => {
    const {token, temp_study, temp_user} = this.state;
    await _addAssigneeToStudy(
      token, { newAssignee: temp_user, studyId: temp_study })
    this.setState({createOpen: false})
  }

  render () {
    const {
      createOpen, 
      isCreateLoading, 
      studies, temp_study, users, temp_user} = this.state;
    return ( <div>
        {<div style={{paddingBottom: '4vh'}}> 
            <Button.Group>
              <Modal
                  onClose={() => this.setState({createOpen: false})}
                  onOpen={() => this.setState({createOpen: true})}
                  open={createOpen}
                  trigger={
                <Button color="" size="massive" icon labelPosition='left'>
                    Add User to Study
                    <Icon name='plus' />
                </Button>}>
                  <div style={{padding: '3vh'}}>
                      <Header>Study</Header>
                      <Card.Group>
                        {studies && studies.map(
                            (study) => <Card 
                                    style={{backgroundColor: temp_study === study.value ? "#6982A5" : ""}} 
                                    onClick={() => {this.setState({temp_study: study.value})}}>
                                    <Card.Content>
                                        <Card.Header>{study.text}</Card.Header>
                                    </Card.Content>
                                </Card>)}
                    </Card.Group>
                    <Header>New Assignee</Header>
                    <div style={{overflowY: 'auto'}}>
                      <Card.Group>
                          {users && users.map(
                              (user) => <Card 
                                      style={{backgroundColor: temp_user === user.value ? "#6982A5" : ""}} 
                                      onClick={() => {this.setState({temp_user: user.value})}}>
                                      <Card.Content>
                                          <Card.Header>{user.text}</Card.Header>
                                      </Card.Content>
                                  </Card>)}
                      </Card.Group>
                    </div>
                  </div>
                    <Modal.Actions>
                        <Button             
                            content="Cancel"
                            labelPosition='right'
                            icon='times' color='red' 
                            onClick={() => this.setState(
                                {createOpen: false})}>
                        </Button>
                        <Button
                            loading={isCreateLoading}
                            content="Create"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => this.setState(
                                {createOpen: false} && this.addAssigneeToStudy())}
                            positive
                        />
                    </Modal.Actions>
                  </Modal>

            </Button.Group>

        </div>
        }   
        </div>
    );
  }
}

export default AddToStudyModal;
