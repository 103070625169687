import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import { Container } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import NavBar from '../NavBar'
import NewModal from '../AdminControls/NewModal'
import Footer from '../Utils/Footer'
import ListObj from '../AdminControls/ListObj'

class Studies extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            token: JSON.parse(
                Cookies.get("deepstain-access")).token,
            profileControl: false
        }
        window.document.body.style.overflow = "hidden"
    }

    toggleProfileControl = () => {
        const { profileControl } = this.state;
        this.setState({ profileControl: !profileControl })
    }


    render() {
        const { profileControl } = this.state;
        const panelsOff = e => {
            this.setState({
                profileControl: false
            })
        }
        return (
            <div>
                <NavBar
                    profileControl={profileControl}
                    toggleProfileControl={this.toggleProfileControl} />
                {<div>
                    <Container
                        blurring
                        onClick={panelsOff}
                        style={{
                            paddingTop: '2vh',
                            paddingLeft: '0.5vh',
                            height: '82vh'
                        }}>
                        <div style={{ padding: '4vh' }}>
                            <NewModal
                                obj_name={"Study"}
                                route_obj={"studies"} />
                        </div>
                        <ListObj obj_route="studies" results={false}/>
                    </Container>
                    <Footer />
                </div>}
            </div>
        );
    }
}

export default withRouter(Studies);
