import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import { Button, Container, Divider, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import NavBar from '../NavBar'
import Footer from '../Utils/Footer'
import ReviewPipelineCRUD from '../AdminControls/ReviewPipeline';
import ReviewPipelineProvider, { useReviewPipelines } from 'Components/AdminControls/ReviewPipeline/ReviewPipelineProvider';

class ReviewPipelines extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			token: JSON.parse(
				Cookies.get("deepstain-access")).token,
			profileControl: false
		}
		window.document.body.style.overflow = "hidden";
	}

	toggleProfileControl = () => {
		const { profileControl } = this.state;
		this.setState({ profileControl: !profileControl })
	}

	newReviewPipeline = () => {
		const { setReviewPipelines } = useReviewPipelines();
		const newReviewPipeline = React.useCallback(() => {
			setReviewPipelines((reviewPipelines) => [...reviewPipelines, {
				name: "new review pipeline",
				status: -1,
				layers: [{
					reviewers: [],
				}]
			}]);
		}, [setReviewPipelines]);

		return (
			<Button onClick={newReviewPipeline} size="big" icon labelPosition='left'>
				<Icon name='clone outline' />
				New Review Pipeline
			</Button>
		)
	}

	render() {
		const { profileControl } = this.state;
		const panelsOff = e => {
			this.setState({
				profileControl: false
			})
		}
		return (
			<div>
				<NavBar
					profileControl={ profileControl }
					toggleProfileControl={ this.toggleProfileControl } />
				{ <div style={ { padding: '4vh' } }>
					<Container
						blurring
						fluid
						onClick={ panelsOff }
						style={ {
							paddingLeft: '0.5vh',
							height: '82vh'
						} }>
						<ReviewPipelineProvider>
							<this.newReviewPipeline/>
							<ReviewPipelineCRUD />
						</ReviewPipelineProvider>
						<Divider />
					</Container>
					<Footer />
				</div> }
			</div>
		);
	}
}

export default withRouter(ReviewPipelines);
