import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import {
  Header, Button, Modal, Icon, Card} from 'semantic-ui-react'
import 'react-dropzone-uploader/dist/styles.css'
import {_removeAssigneeFromOrgan, _read } from '../../Lib/canvas';


class DeleteOrganModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      token: JSON.parse(Cookies.get("deepstain-access")).token,    
      createOpen: false,
      temp_organs: [], 
      temp_user: undefined,
      organs: [], 
      users: []
    }
  }

  componentDidMount(){
    this.getUsers();
  }

    getOrgan = async (user) => {
        const {token} = this.state
        const s = await _read(token, "organs")
        const res = s.results

        if (res.length === 0)
          return;
        const sOptions = []
        for (let i = 0; i < res.length; i++){
          if (res[i].assignees.includes(user)){
            console.log('ya')
            const mn = res[i].name
            const mid = res[i].id
            const t = {
              key: mn,
              text: mn,
              value: mid
            }
            sOptions.push(t)
          }
          
        }
        this.setState({organs: sOptions})
    }

    getUsers = async () => {
        const {token} = this.state
        let s = await _read(token, "users", )
        const res = s.results
        s = await _read(token, "users", s.totalResults)
        if (res.length === 0)
          return;
        const sOptions = []
        for (let i = 0; i < res.length; i++){
          const mn = res[i].email
          const mi = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mi
          }
          sOptions.push(t)
        }
        this.setState({users: sOptions})
    }

  removeAssigneeFromOrgan = async () => {
    const {token, temp_organs, temp_user} = this.state;
    for (let organ of temp_organs){
      await _removeAssigneeFromOrgan(
        token, { assignee: temp_user, organId: organ })
    }

    this.setState({createOpen: false})
  }

  handleSelect = async (organ) => {
    const {temp_organs} = this.state; 
    if(!temp_organs.includes(organ.value)){
      temp_organs.push(organ.value)
      this.setState({temp_organs: temp_organs})
    }
    else {
      var index = temp_organs.indexOf(organ.value);
      if (index !== -1) {
        temp_organs.splice(index, 1);
      }  
    }
  }

  render () {
    const {
      createOpen, 
      isCreateLoading, 
      organs, temp_organs, users, temp_user} = this.state;
    return ( <div>
        {<div style={{paddingBottom: '4vh'}}> 
            <Button.Group>
              <Modal
                  onClose={() => this.setState({createOpen: false})}
                  onOpen={() => this.setState({createOpen: true})}
                  open={createOpen}
                  trigger={
                <Button color="" size="massive" icon labelPosition='left'>
                    Remove Organ from User
                    <Icon name='plus' />
                </Button>}>
                  <div style={{padding: '3vh'}}>
                  <Header>New Assignee</Header>
                    <div style={{overflowY: 'auto'}}>
                      <Card.Group>
                          {users && users.map(
                              (user) => <Card 
                                      style={{backgroundColor: temp_user === user.value ? "#6982A5" : ""}} 
                                      onClick={async () => {
                                        this.setState({temp_user: user.text})
                                        await this.getOrgan(user.value)}
                                      }>
                                      <Card.Content>
                                          <Card.Header>{user.text}</Card.Header>
                                      </Card.Content>
                                  </Card>)}
                      </Card.Group>
                    </div>
                      
                      <Header>organ</Header>
                      <Card.Group>
                        {organs && organs.map(
                            (organ) => <Card 
                                    style={{backgroundColor: temp_organs.includes(organ.value) ? "#6982A5" : ""}} 
                                    onClick={() => {
                                      this.handleSelect(organ)
                                    }}>
                                      
                                    <Card.Content>
                                        <Card.Header>{organ.text}</Card.Header>
                                    </Card.Content>
                                </Card>)}
                    </Card.Group>
                    
                  </div>
                    <Modal.Actions>
                        <Button             
                            content="Cancel"
                            labelPosition='right'
                            icon='times' color='red' 
                            onClick={() => this.setState(
                                {createOpen: false})}>
                        </Button>
                        <Button
                            loading={isCreateLoading}
                            content="Create"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => this.setState(
                                {createOpen: false} && this.removeAssigneeFromOrgan())}
                            positive
                        />
                    </Modal.Actions>
                  </Modal>

            </Button.Group>

        </div>
        }   
        </div>
    );
  }
}

export default DeleteOrganModal;
