import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import { List, Loader, Message } from 'semantic-ui-react'
import { _read } from 'Lib/canvas';
import 'App.css';
import { routes } from 'Lib/constants';
import { useReviewPipelines } from './ReviewPipelineProvider';
import ReviewPipeline from './ReviewPipeline';

export default function ReviewPipelineCRUD() {
	const token = React.useMemo(() => JSON.parse(Cookies.get("deepstain-access")).token, []);

	const { reviewPipelines, reviewPipelineQuery } = useReviewPipelines();
	const [studies, setStudies] = React.useState([]);
	const [users, setUsers] = React.useState([]);
	const [studyIDtoStudy, setStudyIDtoStudy] = React.useState({});

	const getStudies = React.useCallback(async () => {
		return await _read(token, routes.studies);
	}, [token]);

	const getUsers = React.useCallback(async () => {
		return await _read(token, routes.users);
	}, [token]);

	React.useEffect(() => {
		console.log("reviewPipelines - ", reviewPipelines);
	}, [reviewPipelines]);

	React.useEffect(() => {
		async function fetchData() {
			const _studies = await getStudies();
			if (_studies) {
				setStudies(_studies);

				const _studyIDtoStudy = {};
				_studies.forEach(study => {
					_studyIDtoStudy[study.id] = study;
				})
				setStudyIDtoStudy(_studyIDtoStudy);
			}

			const _users = await getUsers();
			if (_users) {
				setUsers(_users.results);
			}
		}
		fetchData();
	}, [getStudies, getUsers]);

	/* TODO: convert studies and users to react providers as well, and actually get "isLoading" */
	if (reviewPipelineQuery.isLoading || studies.length === 0 || users.length === 0)
		return <Loader active inline='centered' />;

	if (reviewPipelineQuery.isError)
		return <Message error>{ reviewPipelineQuery.error.toString() }</Message>;

	return (
		<div>
			<style jsx>{ `
				.asdf {
					display: 'flex';
					flexDirection: 'column';
					backgroundColor: 'lightgrey';
					padding: '1em';
					borderRadius: '0.5em';
				}
				.wrapper {
					max-height: 65vh;
					overflow-y: auto;
				}
			`}</style>
			{ <div className='wrapper'>
				<div className='flex-col'>
					{ reviewPipelines.length === 0 ?
						<Message info>No review pipelines found.</Message>
						:
						<List>
							{ reviewPipelines.map((rpipe, i) =>
								<List.Item>
									<ReviewPipeline rpipe={ rpipe } studyOptions={ studies } userOptions={ users } studyIDtoStudy={ studyIDtoStudy }></ReviewPipeline>
								</List.Item>
							) }
						</List>
					}
				</div>
			</div> }
		</div>
	);
}
