import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import { Header, Table, Label } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { _read, _readById, _updateById } from 'Lib/canvas'
import DraggableList from '../DraggableList';
import { routes } from 'Lib/constants';


class ListObj extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			objs: undefined,
			token: JSON.parse(
				Cookies.get("deepstain-access")).token,
		}
		window.document.body.style.overflow = "hidden"
	}

	componentDidMount() {
		this.getObjs()
	}

	getObjs = async () => {
		const { obj_route, results } = this.props;
		const { token } = this.state;
		const alls = await _read(token, obj_route)
		let r = undefined;
		if (results) {
			r = await alls.results;
		} else {
			r = await alls;
		}

		const modelNames = await this.obj2Models(r);
		let counter = 0;
		let new_r = [];
		for (let pipeline of r) {
			new_r.push({
				name: pipeline.name,
				id: pipeline.id,
				models: await modelNames[counter],
				models_ids: pipeline.models,
				organ: pipeline.organ
			})
			counter += 1;
		}
		this.setState({ objs: new_r })
	}

	pipelineUpdate = async (idx, new_models) => {
		const { token } = this.state;
		let fa = []
		for (let model of new_models) {
			fa.push(model.id)
		}
		await _updateById(token, "pipelines", idx, { models: fa })
	}

	idToName = async (model_ids) => {
		const { token } = this.state;
		let new_array = []
		for (let idx of model_ids) {
			const model = await _readById(token, "predictors", idx)
			new_array.push(model.name)
		}
		return new_array
	}

	obj2Models = async (objs) => {
		return objs.map(async (obj) => {
			return await this.idToName(obj.models)
		})
	}

	render() {
		const { objs } = this.state;
		return (
			<div>
				{ <div style={ { maxHeight: '65vh', overflowY: 'auto' } } >
					<Table>
						<Table.Body>
							{ objs !== undefined && objs.map((obj) =>
								<div>
									<Table.Row>
										{ Object.keys(obj).sort().map((key, i) => String(key) === "models" && (
											<Table.Cell>
												<Header>{ obj.name }
													<div style={ { padding: '1vh' } }>
														<Label onDoubleClick={ async () => {
															const { token } = this.state;
															await _updateById(token, "pipelines", obj.id, {
																organ: prompt("New Organ is:")
															})
															this.getObjs()
														} } ribbon>{ obj.organ }</Label></div></Header>


												<DraggableList
													onDragEnd={ (new_models) => {
														this.pipelineUpdate(obj.id, new_models) }}
													items={ obj.models.map((model, i) => {
														return { content: model, id: obj.models_ids[i] }})}
													obj_route={routes.predictors}
													obj_content={(x) => x.name }
													addItemMenuHeader="Add Model to Pipeline"
													addItemMenuDescription="Select the model you'd like to add." />
											</Table.Cell>)) }
									</Table.Row>
								</div>
							) }
						</Table.Body>
					</Table>
				</div> }
			</div>
		);
	}
}

export default withRouter(ListObj);
