import React from 'react';
import Cookies from "js-cookie"
import 'semantic-ui-css/semantic.min.css'
import {
  Header, Button, Modal, Icon, Card} from 'semantic-ui-react'
import 'react-dropzone-uploader/dist/styles.css'
import {_removeAssigneeFromStudy, _read } from '../../Lib/canvas';


class DeleteStudyModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      token: JSON.parse(Cookies.get("deepstain-access")).token,    
      createOpen: false,
      temp_studies: [], 
      temp_user: undefined,
      studies: [], 
      users: []
    }
  }

  componentDidMount(){
    this.getUsers();
  }

  getStudy = async (user) => {
      const {token} = this.state
      const s = await _read(token, "studies")
      const res = s

      if (res.length === 0)
        return;
      const sOptions = []
      for (let i = 0; i < res.length; i++){
        if (res[i].assignee.includes(user)){
          const mn = res[i].name
          const mid = res[i].id
          const t = {
            key: mn,
            text: mn,
            value: mid
          }
          sOptions.push(t)
        }
      }
      this.setState({studies: sOptions})
  }

  getUsers = async () => {
      const {token} = this.state
      let s = await _read(token, "users", )
      const res = s.results
      s = await _read(token, "users", s.totalResults)
      if (res.length === 0)
        return;
      const sOptions = []
      for (let i = 0; i < res.length; i++){
        const mn = res[i].email
        const mi = res[i].id
        const t = {
          key: mn,
          text: mn,
          value: mi
        }
        sOptions.push(t)
      }
      this.setState({users: sOptions})
  }

  removeAssigneeFromStudy = async () => {
    const {token, temp_studies, temp_user} = this.state;
    for (let study of temp_studies){
      await _removeAssigneeFromStudy(
        token, { userId: temp_user, studyId: study })
    }

    this.setState({createOpen: false})
  }

  handleSelect = async (study) => {
    const {temp_studies} = this.state; 
    if(!temp_studies.includes(study.value)){
      temp_studies.push(study.value)
      this.setState({temp_studies: temp_studies})
    }
    else {
      var index = temp_studies.indexOf(study.value);
      if (index !== -1) {
        temp_studies.splice(index, 1);
      }  
    }
  }

  render () {
    const {
      createOpen, 
      isCreateLoading, 
      studies, temp_studies, users, temp_user} = this.state;
    return ( <div>
        {<div style={{paddingBottom: '4vh'}}> 
            <Button.Group>
              <Modal
                  onClose={() => this.setState({createOpen: false})}
                  onOpen={() => this.setState({createOpen: true})}
                  open={createOpen}
                  trigger={
                <Button color="" size="massive" icon labelPosition='left'>
                    Remove Study from User
                    <Icon name='plus' />
                </Button>}>
                  <div style={{padding: '3vh'}}>
                  <Header>New Assignee</Header>
                    <div style={{overflowY: 'auto'}}>
                      <Card.Group>
                          {users && users.map(
                              (user) => <Card 
                                      style={{backgroundColor: temp_user === user.value ? "#6982A5" : ""}} 
                                      onClick={async () => {
                                        this.setState({temp_user: user.value})
                                        await this.getStudy(user.value)}
                                      }>
                                      <Card.Content>
                                          <Card.Header>{user.text}</Card.Header>
                                      </Card.Content>
                                  </Card>)}
                      </Card.Group>
                    </div>
                      
                      <Header>study</Header>
                      <Card.Group>
                        {studies && studies.map(
                            (study) => <Card 
                                    style={{backgroundColor: temp_studies.includes(study.value) ? "#6982A5" : ""}} 
                                    onClick={() => {
                                      this.handleSelect(study)
                                    }}>
                                      
                                    <Card.Content>
                                        <Card.Header>{study.text}</Card.Header>
                                    </Card.Content>
                                </Card>)}
                    </Card.Group>
                    
                  </div>
                    <Modal.Actions>
                        <Button             
                            content="Cancel"
                            labelPosition='right'
                            icon='times' color='red' 
                            onClick={() => this.setState(
                                {createOpen: false})}>
                        </Button>
                        <Button
                            loading={isCreateLoading}
                            content="Create"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => this.setState(
                                {createOpen: false} && this.removeAssigneeFromStudy())}
                            positive
                        />
                    </Modal.Actions>
                  </Modal>

            </Button.Group>

        </div>
        }   
        </div>
    );
  }
}

export default DeleteStudyModal;
